import {z} from "zod";

export const extSensorsRevenueShareSchema = z.object({
  semdatex: z.number().min(0),
  biotronik: z.number().min(0),
  docCirrus: z.number().min(0),
  getemed: z.number().min(0),
})

export const implantRevenueShareSchema = z.object({
  semdatex: z.number().min(0),
  biotronik: z.number().min(0),
  docCirrus: z.number().min(0),
})

export const configSchema = z.object({
  getemedFixedCosts: z.number().min(0),
  getemedHotlinePrice: z.number().min(0),
  semdatexFixedCosts: z.number().min(0),
  implantRevenueShare: implantRevenueShareSchema,
  revenueShare: extSensorsRevenueShareSchema,
})

export const clientKeycloakConfigSchema = z.object({
  clientId: z.string(),
  url: z.string(),
  realm: z.string(),
})

export type Config = z.infer<typeof configSchema>
export type ClientKeycloakConfig = z.infer<typeof clientKeycloakConfigSchema>
