import React from 'react';
import {BrowserRouter, Navigate, Route, Routes} from 'react-router-dom';
import Welcome from "./components/Welcome";
import {
  AppBar,
  Box,
  Button,
  createTheme,
  CssBaseline,
  IconButton,
  Stack,
  SxProps,
  ThemeProvider,
  Toolbar,
  Typography
} from '@mui/material';
import {LogoutRounded} from "@mui/icons-material";
import {ProductPage} from "./pages/ProductPage";
import CustomerPage from "./pages/CustomerPage";
import {AddProductPage} from "./pages/AddProductPage";
import UpdateCustomerPage from "./pages/UpdateCustomerPage";
import ConfigPage from "./pages/ConfigPage";
import SettingsIcon from '@mui/icons-material/Settings';
import {Theme} from "@mui/material/styles";
import KeycloakService from "./KeycloakService";

export default function App() {
  const licenseManagerTheme =
    createTheme({
      palette: {
        primary: {
          main: '#008E7F', // Primary Green
        },
        secondary: {
          main: '#45D8C3', // Secondary Green
        },
        text: {
          primary: '#494547', // Text + Icon Dark Gray
          secondary: '#9D9D9D', // Secondary Dark Gray
        },
        error: {
          main: '#EF7B45', // Primary Orange (for error states or other accents)
        },
        background: {
          default: '#F8F8F8', // Background Light
          paper: '#FFFFFF', // Background Secondary,
        },
        action: {
          disabledBackground: '#ECECEC', // Light Gray for disabled buttons, cards, etc.
        },
      },
      components: {
        MuiIconButton: {
          styleOverrides: {
            colorPrimary: 'black'
          }
        },
        MuiAppBar: {
          styleOverrides: {
            colorPrimary: {
              backgroundColor: '#ECECEC'
            }
          }
        },
        MuiButton: {
          styleOverrides: {
            containedPrimary: {
              backgroundColor: '#008E7F',
              '&:hover': {
                backgroundColor: '#006f65',
              },
            },
            containedSecondary: {
              backgroundColor: '#EF7B45',
              '&:hover': {
                backgroundColor: '#d66738',
              },
            },
          },
        },
        MuiCard: {
          styleOverrides: {
            root: {
              backgroundColor: '#ECECEC', // Light Gray for cards
            },
          },
        },
      },
    })

  const findCorrespondingRef = (ref: string) => {
    switch (ref) {
      case 'Kunden':
        return '/customers';
      case 'Produkte':
        return '/products/inCareNetHF';
      case 'Reporting':
        return '/reporting';
      case 'Konfiguration':
        return "/configuration";
      default:
        return '/';
    }
  }


  function correctButtonSX(item: string): SxProps<Theme> {
    const isCurrentPage = window.location.pathname.toLowerCase().includes(item.toLowerCase())
    return {
      color: isCurrentPage ? licenseManagerTheme.palette.error.main : licenseManagerTheme.palette.text.primary
    }
  }

  const navItems = ['Kunden', 'Produkte', 'Reporting', "Konfiguration"];

  return (
    <ThemeProvider theme={licenseManagerTheme}>
      <CssBaseline/>
      <BrowserRouter>
        <AppBar position={'static'}>
          <Toolbar>
            <Box sx={{width: '25rem'}}>
              <Typography
                variant="h5"
                component="a"
                href="/"
                color={'error'}
                sx={{
                  display: {xs: 'none', md: 'flex'},
                  fontFamily: 'Roboto, sans-serif',
                  fontWeight: 920,
                  letterSpacing: '.1rem',
                  textDecoration: 'none',
                }}
              >
                LICENSE MANAGER
              </Typography>
            </Box>

            {KeycloakService.instance.isLoggedIn() ?
              <Stack direction={'row'} justifyContent={'left'} sx={{width: '100%'}}>
                <Stack direction={'row'} justifyContent={'left'} sx={{width: '100%'}}>
                  {navItems.map((item) => (
                    <Button href={findCorrespondingRef(item)}
                            variant={'text'}
                            size={'large'}
                            sx={{...correctButtonSX(findCorrespondingRef(item)), mx: .5}}
                            key={item}
                            color={"primary"}>
                      {item}
                    </Button>
                  ))}
                </Stack>

                <Stack direction={'row'} justifyContent={'end'} sx={{width: '100%'}}>
                  <IconButton sx={{ml: 1}} size='large'>
                    <SettingsIcon/>
                  </IconButton>
                  <IconButton sx={{ml: 1}} size='large' onClick={async () => {
                    await KeycloakService.instance.doLogout()
                  }}>
                    <LogoutRounded/>
                  </IconButton>
                </Stack>
              </Stack>
              : null}

          </Toolbar>
        </AppBar>
        {
          KeycloakService.instance.isLoggedIn() ?
            <div style={{width: "100%", display: "block"}}>
              <Routes>
                <Route path="/" element={<Navigate to="/customers" replace />}/>
                <Route path="/products/inCareNetHF" element={<ProductPage productType={'inCareNetHF'}/>}/>
                <Route path="/products/inPace" element={<ProductPage productType={'inPace'}/>}/>
                <Route path="/reporting" element={<div>TODO</div>}/>
                <Route path="/customers" element={<CustomerPage/>}/>
                <Route path="/configuration" element={<ConfigPage/>}/>
                <Route path="/customers/add" element={<UpdateCustomerPage/>}/>
                <Route path="/customers/update/:id" element={<UpdateCustomerPage/>}/>
                <Route path="/products/add"
                       element={<AddProductPage title={"Produkt hinzufügen"}/>}/>
              </Routes>
            </div> :
            <Welcome/>
        }
      </BrowserRouter>
    </ThemeProvider>
  );
}
