import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import KeycloakService from "./KeycloakService";

const env_stage = process.env.REACT_APP_ENV_STAGE;
let dev = env_stage === "development";

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

const renderedApp = () => {
  root.render(
    <React.StrictMode>
      <App/>
    </React.StrictMode>
  );
}

const keycloak = new KeycloakService()
if (dev) {
  keycloak.initDevKeycloak(renderedApp)
} else {
  keycloak.initKeycloak(renderedApp)
}
