// @ts-ignore TS does not recognise keycloak-js for some reason
import Keycloak from 'keycloak-js';
import {getKeycloakConfig} from "./api/config";

export default class KeycloakService {
  static instance: KeycloakService;

  _kc: Keycloak | null = null;
  isDev = process.env.REACT_APP_ENV_STAGE === 'development';

  constructor() {
    if (KeycloakService.instance) {
      return KeycloakService.instance;
    }

    KeycloakService.instance = this;
  }

  doLogin: Function = this.logUnsetKeycloak;
  hasRole: Function = this.logUnsetKeycloak;
  getUsername: Function = this.logUnsetKeycloak;
  doLogout: Function = this.logUnsetKeycloak;
  getToken: Function = this.logUnsetKeycloak;
  isLoggedIn: Function = this.logUnsetKeycloak;

  initKeycloak(onAuthenticatedCallback: Function) {
    if (this._kc !== null) {
      return onAuthenticatedCallback()
    }

    getKeycloakConfig().then((config) => {
      this._kc = new Keycloak(config)

      if (this._kc === null) {
        throw new Error("Something went wrong with the Keycloak configuration");
      }

      this.getUsername = () => this._kc.tokenParsed?.preferred_username;
      this.hasRole = (roles: any[]) => roles.some((role) => this._kc.hasRealmRole(role));
      this.doLogin = this._kc.login.bind(this._kc);
      this.doLogout = this._kc.logout.bind(this._kc);
      this.getToken = () => this._kc.token;
      this.isLoggedIn = this.isDev ? () => true : () => !!this._kc.token;

      this._kc.onTokenExpired = () => {
        this.doLogout().then(() => {
          console.log('Token expired, logging out');
        });
      };

      this._kc.init({
        onLoad: 'check-sso',
        silentCheckSsoFallback: false,
      })
        .then((authenticated: boolean) => {
          if (!authenticated) {
            console.log('User is not authenticated..!');
          } else {
            // Start automatic token refresh
            this.startTokenRefresh();
          }

          onAuthenticatedCallback();
        })
        .catch((e: any) => {
          console.error("Keycloak initialization error:")
          console.error(e)
          alert("Error: Could not initialize Keycloak");
        });
    });
  }

  startTokenRefresh() {
    const refreshInterval = setInterval(() => {
      if (!this._kc) return;

      this._kc.updateToken(60) // Refresh if token expires in <= 60 seconds
        .then((refreshed: boolean) => {
          if (refreshed) {
            console.log('Token has been refreshed refreshed.');
          }
        })
        .catch(() => {
          console.error('Failed to refresh token, logging out...');
          this.doLogout();
          clearInterval(refreshInterval); // Stop refreshing if logout happens
        });
    }, 30000); // Check every 30 seconds
  }

  logUnsetKeycloak() {
    console.warn('Keycloak config not set yet.');
  }

  initDevKeycloak(onAuthenticatedCallback: Function) {
    this.getToken = () => {}
    this.isLoggedIn = () => true
    onAuthenticatedCallback()
  }
}
