import {serverDomain} from "../constants";
import {getGETUrl} from "../utils/helperFunctions";
import {ClientICD, clientICDSchema} from "sharedlibs";
import KeycloakService from "../KeycloakService";

/**
 * This function fetches all ICN-Software objects from the server and returns it as JSON
 *
 * @return {Promise<*>}
 */
export async function getSoftware(query?: any): Promise<ClientICD[]> {
  const url = getGETUrl("software", query)

  let response;
  const token = KeycloakService.instance.getToken()
  try {
    response = await fetch(url, {
      method: 'GET',
      headers: {
        "Authorization": `Bearer ${token}`
      }
    })
    const json = await response.json()

    if (Array.isArray(json)) {
      return json.map((elem) => {
        return clientICDSchema.parse(elem)
      })
    } else {
      throw new Error()
    }
  } catch (e) {
    throw new Error('Error while fetching software from server')
  }
}

export async function loadRefreshCountDate() {
  const request = await fetch(`${serverDomain}/software/lastUpdate`, {
    method: 'GET',
    headers: {
      "Authorization": `Bearer ${KeycloakService.instance.getToken()}`
    }
  })

  return await request.json()
}

/**
 * Returns the ICN-Software object with the given SOL-ID
 * A ICN-Software object contains detailed data about a SOL
 *
 * @param solId
 */
export async function getICNDetails(solId: string) {
  const token = KeycloakService.instance.getToken()
  try {
    let response = await fetch(`${serverDomain}/software/details?id=${solId}`, {
      method: 'GET',
      headers: {
        "Authorization": `Bearer ${token}`
      }
    })
    return await response.json()
  } catch (e) {
    throw new Error('Error while fetching ICN Details from server')
  }
}

/**
 * Adds a Software object to our DB
 *
 * @param softwareObject
 */
export async function addSoftware(softwareObject: ClientICD) {
  const token = KeycloakService.instance.getToken()
  const response = await fetch(`${serverDomain}/software`, {
    method: 'POST',
    headers: {
      "Content-Type": "application/json",
      "Authorization": `Bearer ${token}`
    },
    body: JSON.stringify(softwareObject)
  })


  if (!response.ok) {
    throw new Error(`Could not add inCareNet-HF Object! HTTP status: ${response.status}`);
  }
}


/**
 * Updates a Software object in our DB
 *
 * @param {Object} softwareObject
 * @param {Object} softwareObject.device - The software object.
 * @param {string} softwareObject.user - The user who has done the action.
 * @return {Promise<void>}
 */
export async function updateSoftware(softwareObject: ClientICD): Promise<void> {
  const token = KeycloakService.instance.getToken()
  const response = await fetch(`${serverDomain}/software`, {
    method: 'PUT',
    headers: {
      "Content-Type": "application/json",
      "Authorization": `Bearer ${token}`
    },
    body: JSON.stringify(softwareObject)
  })

  if (!response.ok) {
    throw new Error(`Could not save inCareNet-HF Object! HTTP status: ${response.status}`);
  }
}

/**
 * Deletes a Software object from our DB
 *
 * @param softwareId
 */
export async function deleteSoftware(softwareId: string) {
  const token = KeycloakService.instance.getToken()

  try {
    const response = await fetch(`${serverDomain}/software/${softwareId}`, {
      method: "DELETE",
      headers: {
        "Authorization": `Bearer ${token}`
      },
    })

    if (!response.ok) {
      throw new Error()
    }
  } catch (e) {
    console.error(e)
    throw new Error('Error while deleting a device')
  }
}
