import {io} from "socket.io-client";
import {serverDomain} from "../constants";
import KeycloakService from "../KeycloakService";

export function initateSocketIOConnection() {
  const token = KeycloakService.instance.getToken()

  return io(serverDomain, {
    extraHeaders: {
      'Authorization': `Bearer ${token}`
    }
  })
}
