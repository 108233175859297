import {serverDomain} from "../constants";
import {Config, configSchema, ClientKeycloakConfig, clientKeycloakConfigSchema} from "sharedlibs";
import KeycloakService from "../KeycloakService";

/**
 * Fetches the config from the server
 */
export async function getConfig(): Promise<Config> {
  const token = KeycloakService.instance.getToken()
  const response = await fetch(`${serverDomain}/config`, {
    method: 'GET',
    headers: {
      "Authorization": `Bearer ${token}`
    }
  })
  const json = await response.json()
  return configSchema.parse(json)
}

/**
 * Updates the config on the server
 * @param config
 */
export async function updateConfig(config: Config) {
  const token = KeycloakService.instance.getToken()
  const response = await fetch(`${serverDomain}/config`, {
    method: 'POST',
    headers: {
      "Authorization": `Bearer ${token}`,
      "Content-Type": "application/json",
    },
    body: JSON.stringify(config)
  })

  if (!response.ok) {
    throw new Error(`Could not update config! HTTP status: ${response.status}`);
  }
}

export async function getKeycloakConfig(): Promise<ClientKeycloakConfig> {
  const cachedConfig = sessionStorage.getItem("keycloakConfig")

  if (cachedConfig) {
    return JSON.parse(cachedConfig)
  }

  const response = await fetch(`${serverDomain}/clientconfig/keycloak`, {
    method: 'GET',
  })
  const config = await response.json()
  const parsedConfig = clientKeycloakConfigSchema.parse(config)
  sessionStorage.setItem("keycloakConfig", JSON.stringify(parsedConfig));
  return parsedConfig
}
