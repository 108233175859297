import {Button, Typography} from "@mui/material";
import KeycloakService from "../KeycloakService";

const Welcome = () => (
  <div>
    <Typography variant="h1" color={'primary'} fontFamily={'poppins'}
                sx={{textAlign: 'center', paddingTop: '5%'}}>Guten Tag</Typography>
    <Typography variant="h4" color={'primary'} fontFamily={'poppins'} sx={{textAlign: 'center'}}>
      Bitte loggen Sie sich ein
    </Typography>

    <Button sx={{margin: '0 auto', marginTop: 3, display: 'flex'}} variant={'contained'}
            onClick={() => KeycloakService.instance.doLogin()}>Einloggen</Button>

  </div>
);

export default Welcome;
