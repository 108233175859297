import {serverDomain} from "../constants";
import {getGETUrl} from "../utils/helperFunctions";
import {ClientDevice, clientDeviceSchema} from "sharedlibs";
import {z} from "zod";
import KeycloakService from "../KeycloakService";

/**
 * Gets all devices
 *
 * @param query
 */
export async function getDevices(query?: any): Promise<ClientDevice[]>{
  let response;
  const token = KeycloakService.instance.getToken()

  const url = getGETUrl("devices", query)

  try {
    response = await fetch(url, {
      method: 'GET',
      headers: {
        "Authorization": `Bearer ${token}`
      }
    })
    const json = await response.json()
    return z.array(clientDeviceSchema).parse(json)
  } catch (e) {
    throw new Error('Error while fetching devices from server')
  }
}

/**
 * Adds a device to our DB
 *
 * @param deviceObject
 */
export async function addDevice(deviceObject: ClientDevice){
  const token = KeycloakService.instance.getToken()
  const response = await fetch(`${serverDomain}/devices`, {
    method: 'POST',
    headers: {
      "Content-Type": "application/json",
      "Authorization": `Bearer ${token}`
    },
    body: JSON.stringify(deviceObject)
  })

  if (!response.ok) {
    throw new Error(`Could not save Device Object! HTTP status: ${response.status}`);
  }

}

/**
 *
 * Updates a device in our DB
 *
 * @param {ClientDevice} deviceObject
 * @return {Promise<void>}
 */
export async function updateDevice(deviceObject: ClientDevice) {
  const token = KeycloakService.instance.getToken()
  const response = await fetch(`${serverDomain}/devices`, {
    method: 'PUT',
    headers: {
      "Content-Type": "application/json",
      "Authorization": `Bearer ${token}`
    },
    body: JSON.stringify(deviceObject)
  })

  if (!response.ok) {
    throw new Error(`Could not save Device Object! HTTP status: ${response.status}`);
  }
}

/**
 * Deletes a device in our DB
 *
 * @param deviceId
 */
export async function deleteDevice(deviceId: string) {
  const token = KeycloakService.instance.getToken()

  try {
    const response = await fetch(`${serverDomain}/devices/${deviceId}`, {
      method: "DELETE",
      headers: {
        "Authorization": `Bearer ${token}`
      },
    })

    if (!response.ok) {
      throw new Error()
    }
  } catch (e) {
    throw new Error('Error while deleting a device')
  }
}
