import {z} from 'zod'
import {ICDHistorySchema} from "./IcdHistoryTypes";
import {inactiveDeviceCountSchema} from "./IcdDetailsTypes";
import {addressSchema} from "./CustomerTypes";

export const leanActiveDeviceCountSchema = z.object({
  Bio: z.number(),
  Abbott: z.number(),
  Boston: z.number(),
  Medtronic: z.number(),
  MicroPort: z.number(),
  ExternalSensors: z.number(),
})

export const leanDeviceCountsSchema = z.object({
  deviceCountActive: leanActiveDeviceCountSchema,
  deviceCountInactive: inactiveDeviceCountSchema
})

export const clientICDSchema = z.object({
  _id: z.string().optional(),
  os: z.string().optional(), // TODO set to not optional if db is corrected
  version: z.string(),
  solName: z.literal("incardio-dashboard"),
  customerId: z.string(),
  url: z.string(),
  token: z.string(),
  system: z.string().optional(), // TODO set to not optional if db is corrected
  getemedHotlineSupport: z.boolean(),
  priceSensorsPerPatient: z.number(),
  priceImplantsPerPatient: z.number(),
  currentDeviceCount: leanDeviceCountsSchema,
  isDeviceInactiveFailed: z.boolean(),
  errorMessage: z.string(),
  discount: z.number(),
  tmzActiveSince: z.string(),
  customerName: z.string(),
  biotronikClientName: z.string(),
  bostonSAPID: z.string(),
  customerNumber: z.string(),
  location: z.string(),
})

export const fullClientIcdSchema = clientICDSchema.extend({
  history: z.array(ICDHistorySchema),
})

export const ICDOfDateSchema = z.object({
  _id: z.string(),
  date: z.string(),
  customerId: z.string(),
  customerName: z.string(),
  customerNumber: z.string(),
  doctorName: z.string(),
  biotronikClientName: z.string(),
  biotronikCustomerNumber: z.string(),
  eTin: z.string(),
  biotronikExternalSensorsPrice: z.number(),
  biotronikImplantPrice: z.number(),
  system: z.string(),
  address: addressSchema,
  bostonSAPID: z.string(),
  os: z.string().optional(),
  version: z.string(),
  solName: z.literal("incardio-dashboard"),
  url: z.string(),
  token: z.string(),
  getemedHotlineSupport: z.boolean(),
  priceSensorsPerPatient: z.number(),
  priceImplantsPerPatient: z.number(),
  discount: z.number(),
  tmzActiveSince: z.string(),
  deviceCount: leanDeviceCountsSchema
})

export type LeanDeviceCounts = z.infer<typeof leanDeviceCountsSchema>
export type ClientICD = z.infer<typeof clientICDSchema>
export type FullClientICD = z.infer<typeof fullClientIcdSchema>
export type ICDOfDay = z.infer<typeof ICDOfDateSchema>
